export function getVideo(src) {
  const video = document.createElement("video")
  video.crossOrigin = "anonymous"
  video.src = src
  video.muted = true

  return video
}

export async function getTrackReader(video) {
  if (typeof MediaStreamTrackProcessor === 'undefined') {
    window.MediaStreamTrackProcessor = class MediaStreamTrackProcessor {
      constructor(track) {
        if (track.kind == "video") {
          this.readable = new ReadableStream({
            async start(controller) {
              this.video = document.createElement("video");
              this.video.srcObject = new MediaStream([track]);
              await Promise.all([this.video.play(), new Promise(r => this.video.onloadedmetadata = r)]);
              this.track = track;
              this.canvas = new OffscreenCanvas(this.video.videoWidth, this.video.videoHeight);
              this.ctx = this.canvas.getContext('2d', {desynchronized: true});
              this.t1 = performance.now();
            },
            async pull(controller) {
              while (performance.now() - this.t1 < 1000 / track.getSettings().frameRate) {
                await new Promise(r => requestAnimationFrame(r));
              }
              this.t1 = performance.now();
              this.ctx.drawImage(this.video, 0, 0);
              controller.enqueue(new VideoFrame(this.canvas, {timestamp: this.t1}));
            }
          });
        }
      }
    };
  }

  await video.play()

  const userAgent = navigator.userAgent
  let stream = null

  try {
    if (userAgent.indexOf('Firefox') > -1) {
      stream = video.mozCaptureStream()
    } else {
      stream = video.captureStream()
    }
  } catch (e) {
    return
  }

  const [track] = stream.getVideoTracks()
  await video.pause()

  const processor = new MediaStreamTrackProcessor(track)
  return processor.readable.getReader()
}
