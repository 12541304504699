import DownloadIcon from '@mui/icons-material/Download'
import FolderZipIcon from '@mui/icons-material/FolderZip'
import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import CardActions from '@mui/material/CardActions'
import CardMedia from '@mui/material/CardMedia'
import Stack from '@mui/material/Stack'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import FileSaver from 'file-saver'
import JSZip from 'jszip'
import { useState } from 'react'
import { useSelector } from 'react-redux'


import { getTrackReader, getVideo } from './extractorService'

export default function Extractor({videoUrl}) {
  const canExtract = useSelector((state) => state.extractor.canExtract)
  const extractionStart = useSelector((state) => state.extractor.extractionStart)

  const [numberOfFramesToExtract, setNumberOfFramesToExtract] = useState(10)
  const [distanceBetweenFrames, setDistanceBetweenFrames] = useState(100)
  const [frames, setFrames] = useState([])

  async function extractFrames() {
    const video = getVideo(videoUrl)
    const reader = await getTrackReader(video)

    let extracted = 0
    let extractionPoint = extractionStart
    let previousTime = null

    setFrames([])

    async function extractSingleFrame() {
      video.currentTime = extractionPoint

      await video.play()
      await new Promise(r => setTimeout(r, 50))  // prevent first frame from appearing

      reader.read().then(async({ done, value }) => {
        if (value) {
          const time = video.currentTime

          // needed when video metadata is incorrect
          let options = {resizeQuality: "high"}
          if (value.codedWidth != video.videoWidth) {
            options.resizeWidth = video.videoWidth
          }
          if (value.codedHeight != video.videoHeight) {
            options.resizeHeight = video.videoHeight
          }

          const bitmap = await createImageBitmap(value, options)

          await video.pause()
          extractionPoint += distanceBetweenFrames / 1000

          const canvas = document.createElement('canvas')
          canvas.width = bitmap.width
          canvas.height = bitmap.height
          let ctx = canvas.getContext('bitmaprenderer')
          ctx.transferFromImageBitmap(bitmap)
          const blob = await new Promise(resolve => canvas.toBlob(resolve));

          const newFrame = {
            image: blob,
            time: time,
            previousTime: previousTime,
          }

          previousTime = time

          setFrames((oldFrames) => ([...oldFrames, newFrame]))
          value.close();
        }

        if (++extracted < numberOfFramesToExtract) {
          await new Promise(r => setTimeout(r, 100))  // don't overload the system
          await extractSingleFrame()
        } else {
          video.removeAttribute('src')
          await video.load()
        }
      })
    }

    await extractSingleFrame()
  }

  async function downloadAllFrames() {
    const zip = new JSZip()

    frames.map((frame, index) => {
      zip.file(`${index + 1}.png`, frame.image, { binary: true })
    })

    zip.generateAsync({ type: 'blob' }).then(function (content) {
        FileSaver.saveAs(content, `frame-extractor ${new Date().toLocaleString('sv', { timeZoneName: 'short' } )}.zip`)
    })
  }

  function formatTimestamp(timestamp) {
    const time = new Date(timestamp * 1000).toISOString().substr(11, 8)
    const miliseconds = new Date(timestamp * 1000).getMilliseconds()
    return time + "." + miliseconds
  }

  return (
    <Stack spacing={3} alignItems="center" justifyContent="center">
      <div>
        <Typography variant="body1">
          Extraction will start at: <strong>{ formatTimestamp(extractionStart) }</strong>
        </Typography>

        <Typography color="gray" variant="caption">
          (Can be adjusted via video player.)
        </Typography>
      </div>

      <Stack direction="row" spacing={2} alignItems="center" justifyContent="center">
        <TextField
          name="frames"
          type="number"
          label="Number of frames"
          defaultValue={numberOfFramesToExtract}
          onChange={(e) => (setNumberOfFramesToExtract(e.target.value))}
        />
        <TextField
          name="distance"
          type="number"
          label="Distance between frames in ms"
          defaultValue={distanceBetweenFrames}
          onChange={(e) => (setDistanceBetweenFrames(e.target.value))}
        />
        <Button variant="contained" disabled={!canExtract} onClick={extractFrames} size="large">
          Extract
        </Button>
      </Stack>

      <Typography variant="caption">
        Extracting multiple frames helps with getting one that is not blurred.<br/>
        Frames are extracted in video's resolution.<br/>
        Extracting too many might make this application unresponsive!
      </Typography>

      {frames.length > 0 &&
        <Button variant="outlined" startIcon={<FolderZipIcon />} onClick={downloadAllFrames}>
          Download all ({ frames.length }/{ numberOfFramesToExtract })
        </Button>
      }

      {frames.map((frame, index) => (
        <Card key={index}>
          <CardMedia
            component="img"
            image={URL.createObjectURL(frame.image)}
          />
          <CardActions>
            <Stack direction="row" spacing={2} alignItems="center">
              <Button startIcon={<DownloadIcon />} href={URL.createObjectURL(frame.image)} download>
                Download
              </Button>
              <Typography variant="caption">
                #{index + 1}
              </Typography>
              <Typography variant="caption">
                {formatTimestamp(frame.time)}
              </Typography>
              {frame.previousTime &&
                <Typography variant="caption">
                  ({frame.time > frame.previousTime ? '+' : '-'}{Math.floor((frame.time - frame.previousTime) * 1000)}ms)
                </Typography>
              }
            </Stack>
          </CardActions>
        </Card>
      ))}
    </Stack>
  )
}
